.wallpaper-logo-industry {
    background-image: url('../assets/img/brand/wallpaper_login.webp');
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
    font-family: 'Lato', sans-serif;
}

.logo-landing-footer{
    background-color: #ff274c;
    border-radius: 25px;
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.logo-landing-footer img {
  height: 18rem;
  object-fit: contain;
}

@media (min-width: 767px) {
    .left-footer-col {
        text-align: left !important;
    }
    .right-footer-col {
        text-align: right !important;
    }
}
